
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { SessionState } from "@/schemas/Enums";
import clientCache from "@/utils/cacheUtils";
import { ISession } from "@/schemas/ISession";
import { mapState } from "vuex";

@Component({
  name: "TransitionPage",
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class SessionTransition extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;
  // The current session
  session: ISession | undefined;

  created(): void {
    if (!this.session) {
      this.$store
        .dispatch("session/fetchSession")
        .then(() => this.handleSessionStatus(this.session));
    }

  }

  handleSessionStatus(session: ISession | undefined): void {
    if (session.status === SessionState.RunningSingle) {
      if (clientCache.get(`${this.sessionId}:has_submitted_single`)) {
        this.$store.dispatch("session/updateSession", {
          sessionId: this.sessionId,
          session: { status: SessionState.TipsGroup },
        });
        return;
      } else {
        this.$router.push({
          name: "singleSession",
          params: {
            sessionId: this.sessionId,
          },
        });
        return;
      }
    }
    if (session.status === SessionState.Finished) {
      this.$router.push({
        name: "groupSession",
        params: {
          sessionId: this.sessionId,
        },
      });
      return;
    }
  }

  redirectToGroupSession(): void {
    if (this.session?.setting?.tip_overview) {
      this.$router.push({
        name: "tipsOverview",
        params: {
          sessionId: this.sessionId,
        },
      });
      return;
    }
    else{
      this.$router.push({
        name: "groupSession",
        params: {
          sessionId: this.sessionId,
        },
      });
    }
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
